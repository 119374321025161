.input-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 1rem;
}

@media screen and (max-width: 1023px) {
  .input-row {
    &:not(.no-wrap) {
      flex-direction: column;
    }
  }
}

.flex-b-33 {
  flex-basis: 33%;
}
.flex-b-66 {
  flex-basis: 66%;
}
.flex-b-25 {
  flex-basis: 25%;
}
.flex-b-10 {
  flex-basis: 10%;
}
.flex-b-20 {
  flex-basis: 20%;
}
.flex-b-30 {
  flex-basis: 30%;
}
.flex-b-40 {
  flex-basis: 40%;
}
.flex-b-50 {
  flex-basis: 50%;
}
.flex-b-60 {
  flex-basis: 60%;
}
.flex-b-70 {
  flex-basis: 70%;
}
.flex-b-80 {
  flex-basis: 80%;
}
.flex-b-90 {
  flex-basis: 90%;
}
.flex-b-100 {
  flex-basis: 100%;
}

.btb-form-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;

  .btb-form-content {
    flex-grow: 1;
    overflow: auto;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    margin: 0px 4px 8px;
    max-width: 800px;

    .input-row {
      margin: 0px;
    }

    .btb-input {
      &:first-child {
        z-index: 2;
      }

      &:not(:first-child) {
        z-index: 1;
      }
    }
  }

  // section divider
  h1 {
    margin-bottom: 2px;
    position: relative;
    font-size: 21px;
    font-weight: 500;
    margin-left: 10px;
    padding-top: 2px;
    margin-top: 2px;
  }
}

.btb-form-group .btb-form-confirm {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 8px 12px;

  button {
    flex-grow: 0;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  ion-spinner {
    margin-right: 10px;
  }

  &.left-aligned {
    justify-content: flex-start;
  }
}

// unit field
.btb-input span {
  position: absolute;
  top: 33px;
  right: 12px;
}

.btb-input input[type='number'] {
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}

@media (max-width: 1200px) {
  .btb-form-group .input-row {
    &:not(.nobreak) {
      flex-direction: column !important;

      .btb-input {
        margin-right: 0px !important;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.btb-input {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  z-index: 1;
  padding-left: 2px;
  margin: 0px 8px;

  label {
    margin-bottom: 4px;
    margin-left: 2px;
    font-weight: 600;
    font-size: 16px;
  }

  input,
  textarea {
    height: 34px;
    border: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    outline-width: 0px;
    padding-left: 10px;
    &:focus {
      box-shadow: 0px 0px 2px 0px var(--ion-color-primary);
    }

    &[id='select-type'] {
      cursor: pointer;
    }

    &[type='date'] {
      cursor: pointer;
    }

    &:disabled {
      background-color: transparent;
    }
  }

  textarea {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  p[id='errorMsg'] {
    visibility: hidden;
    height: 0px;
    color: $warn-900;
    font-size: 10px;
    margin-left: 8px;
    margin-bottom: 0px;
    line-height: 16px;
    margin-top: 0px;
  }

  &.error {
    label {
      color: $warn-900;
    }

    input,
    textarea {
      border-color: $warn-900;
      box-shadow: 0px 0px 2px 0px $warn-900;
    }

    p[id='errorMsg'] {
      visibility: visible;
      height: initial;
      margin-top: 4px;
    }
  }

  &.icon-left {
    input {
      padding-left: 30px;
    }

    span {
      position: absolute;
      top: 28px;
      left: 6px;
      font-size: 24px;
      transition: transform 0.2s;
      cursor: pointer;
    }
  }

  &.icon-right {
    input {
      padding-right: 30px;
    }

    i-feather {
      position: absolute;
      top: 31px;
      right: 8px;
      cursor: pointer;
    }
  }

  &.hidden {
    label {
      visibility: hidden !important;
      height: 0px !important;
      margin: 0px !important;
      padding: 0px !important;
    }

    input {
      visibility: hidden !important;
      height: 0px !important;
      margin: 0px !important;
      padding: 0px !important;
    }
  }

  .checkbox-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      height: unset !important;
      cursor: pointer;
    }

    label {
      margin-bottom: 0px;
    }
  }

  &:focus-within {
    .suggestions:not(.disabled) {
      max-height: 150px;
      opacity: 1;
    }

    span[id='suggestionArrow'] {
      transform: rotate(-90deg);
    }
  }

  &.error {
    .suggestions {
      padding-top: 24px;
    }
  }

  .suggestions {
    position: absolute;
    width: calc(100% - 2px);
    z-index: -1;
    top: 50px;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    background-color: white;
    transition: max-height 0.2s linear;
    color: black;
    box-shadow: 0px 11px 11px 0px rgb(0 0 0 / 26%);
    opacity: 0;
    max-height: 0px;
    overflow: hidden;

    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      margin-left: 12px;
      margin-bottom: 0px;
      flex-shrink: 0;
    }

    button {
      width: 100%;
      margin: 0px 0px 0px;
      background-color: transparent;
      border-radius: 0px;
      height: 30px;
      position: relative;
      border: none;
      outline: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-shrink: 0;

      &:not([id='mailButton']) {
        padding: 0px 18px;
      }

      &[id='mailButton'] {
        padding: 0px 12px;
        margin-bottom: 4px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        text-align: start;
        margin: 0px;
      }

      &:hover {
        background-color: rgb(209, 203, 203);
      }

      &:active {
        box-shadow: var(--default-box-shadow);
        background-color: rgb(209, 203, 203);
      }
    }
  }
}
