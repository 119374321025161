// 1. utils
@import './utils/icons', './utils/variables';

// 2. base
@import './base/base';

// 3. themes
@import './themes/material-theme', './themes/prime-reset', './themes/scroll-bar';

// 4. components
@import './components/btb-button', './components/btb-card', './components/btb-tab', './components/btb-table.old',
  './components/btb-table'; // 4. layouts

// 5. layouts
@import './layouts/btb-form', './layouts/btb-page', './layouts/layout';

@import 'normalize.css/normalize.css';
// 6. pages

// 7. vendors
