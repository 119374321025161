.btb-button {
  --height: 36px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-light);
  border-radius: 4px;

  height: var(--height);
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: var(--default-box-shadow);
  padding: 0px 14px;
  position: relative;

  &:hover {
    background-color: var(--ion-color-primary-shade);
  }

  &:active {
    box-shadow: var(--default-box-shadow);
    background-color: var(--ion-color-primary-tint);
  }

  &.selected {
    background-color: var(--ion-color-secondary-tint);
    box-shadow: var(--default-box-shadow);
  }

  &.loading {
    ion-spinner {
      opacity: 1;
    }
  }

  &:disabled {
    filter: opacity(0.6);
  }

  &.icon-only {
    width: var(--height);
    border-radius: 50%;
  }
}

.mat-icon-button {
  border-radius: 8px !important;

  .mat-button-wrapper {
    display: flex;
    justify-content: center;
  }

  i-feather {
    display: flex;
  }

  &.filled {
    background-color: $primary-500;
    color: white;
  }
}

.mat-icon-button:hover .mat-button-focus-overlay {
  opacity: 0.1 !important;
}
