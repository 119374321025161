app-native-layout,
app-web-layout {
  --menu-color: $primary-500;
  --font-color: white;
  --font-hover-color: #afcaec;
  --icon-size: 32px;
  --header-height: 78px;
  --margin-top: 24px;
  --menu-active-color: #70a2cb;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 0;
}

.menu {
  --menu-width: 280px;
  height: 100%;
  width: var(--menu-width);
  display: flex;
  flex-direction: column;
  background-color: $primary-500;
  flex-shrink: 0;
}

.menu-header {
  margin: 12px 0 10px;
  width: 100%;
  position: relative;
  color: var(--font-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family);
  padding-left: 12px;
  padding-top: 6px;

  h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 3px;
    color: var(--font-color);
    cursor: default;
  }

  .menu-close-button {
    display: none;
    height: 38px;
    width: 38px;
    padding: 0;
    margin: 0 1rem;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    span {
      font-size: 1.6rem;
    }
  }
}

.menu-backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9998;
  background-color: transparent;
  display: none;
}

.menu-page {
  width: 100%;
  height: 100%;
  padding: 0;
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.menu-page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 32px 0 12px;
  margin: 12px 0 8px;

  &.has-subtitle {
    margin-bottom: 20px !important;
  }

  .menu-open-button {
    display: none;
    height: 38px;
    width: 38px;
    padding: 0;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    color: black !important;
    span {
      font-size: 1.6rem;
    }
  }

  .menu-page-header-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    flex-grow: 1;
    min-width: 0;

    h1 {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: 0;
      margin-left: 8px;
      color: black;
      font-family: var(--font-family);
      cursor: default;
      white-space: nowrap;
      overflow: hidden;
    }

    p {
      position: absolute;
      bottom: -16px;
      margin: 0 0 0 0.6rem;
      line-height: 16px;
      font-size: 14px;
      display: flex;
      align-items: center;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      max-width: calc(100% - 18px);
      span {
        font-weight: 300;
        font-size: 1.4rem;
        margin-right: 0.2rem;
      }
    }
  }
}

.page-content {
  flex-grow: 1;
  padding: 0 36px;
  overflow-y: auto;
  overflow-x: hidden;
}

.menu-page-header .menu-button {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  width: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  outline: none;
}

@media (max-width: 1023px) {
  .page-content {
    padding: 0 12px;
  }
  .menu {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    transition: width 0.2s;
    width: 0;
    overflow-x: hidden;

    &--active {
      width: var(--menu-width) !important;
    }
  }
  .menu-header .menu-close-button {
    display: flex;
  }

  .menu-page-header .menu-open-button {
    display: flex;
  }

  .menu-backdrop {
    display: block;
  }
}

app-customer-selector {
  --customer-select-hover-color: var(--font-hover-color);
  --customer-select-background-color: var(--menu-active-color);
}

account-menu-component {
  flex-grow: 0;
  flex-shrink: 0;
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.profile-button {
  padding: 0.3rem 0.4rem;
  .profile-icon {
    --size: 28px;
    position: relative;
    min-height: var(--size);
    max-height: var(--size);
    min-width: var(--size);
    max-width: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 4px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  span {
    color: black !important;
  }
}
