.btb-table {
  overflow: auto;
  flex-grow: 1;

  thead {
    tr {
      color: #ffffff;
      text-align: left;
    }

    th {
      font-weight: 500;
      background-color: var(--ion-color-primary-tint);
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  th,
  td {
    padding: 6px 12px;
  }

  tbody {
    tr {
      border-bottom: 1px solid #dddddd;

      &.dimmed {
        td[id='iconCol'] button span {
          color: gray;
        }
      }

      &[id='dividerRow'] {
        td {
          background-color: #cacaca;
          font-weight: 500;
        }
      }
    }

    tr:nth-of-type(even) {
      background-color: #f3f3f3;

      &.dimmed {
        background-color: #f8f6f6;
      }
    }

    td {
      &[id='name'] {
        font-weight: 500;
        cursor: pointer;

        &:hover {
          color: var(--ion-color-primary-tint);
        }
      }

      &.skeleton {
        background-color: white;
      }

      &[id='iconCol'] {
        width: 48px;

        button {
          background-color: transparent;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover button &:not(:disabled) span {
          color: var(--ion-color-primary-tint);
          font-family: 'Material Icons';
          content: 'edit';
        }
      }
    }
  }
}
