.btb-table {
  th {
    border-bottom: 1px solid #e3e4e6;
    padding: 8px 16px;
  }

  td {
    border-bottom: 1px solid #e3e4e6;
    padding: 8px 16px;

    .member-display {
      display: flex;
      justify-content: flex-start;

      .member-avatar {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        flex-shrink: 0;
        flex-grow: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .member-info {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        p {
          margin: 0px;
        }

        p#memberName {
          color: black;
        }

        p#memberEmail {
          color: #65676e;
        }
      }
    }

    &.align-right {
      text-align: right;

      button {
        float: right;
      }
    }
  }
}

tr td[id='iconCol'] {
  width: 1%;
  white-space: nowrap;
  padding-right: 22px;
}
