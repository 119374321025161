.p-float-label {
  width: 100%;
  margin-top: 1.8rem;
  margin-bottom: 0.2rem;
}

.field {
  width: 100%;
  margin-bottom: 0;

  small {
    font-size: 0.8rem;
    margin-top: 0.2rem;
    margin-left: 0.8rem;
  }

  &:not(:first-child):not(:only-child) {
    //margin-left: 1rem;
  }
}

.p-float-label textarea:not(.p-filled):not(:focus) ~ label {
  top: 1.4rem;
}

.p-progress-spinner {
  height: 2.6rem !important;
  width: 2.6rem !important;
}

.p-progress-spinner-circle {
  stroke: #184a84 !important;
  stroke-width: 4px;
}

.p-button:has(.mat-outlined) {
  &:not(.p-button-lg) {
    padding-top: 0.55rem !important;
    padding-bottom: 0.55rem !important;
  }

  &:not(.p-button-icon-only) {
    padding-left: 0.8rem;
    .mat-outlined {
      margin-right: 0.42rem !important;
    }
  }
  &.p-button-icon-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 2.8rem !important;
    .p-button-icon {
      font-size: 1.5rem !important;
    }
    &.p-button-sm {
      padding-top: 0.4rem !important;
      padding-bottom: 0.4rem !important;
      width: 2.5rem !important;
    }
  }
}

.p-button.p-button-plain {
  color: black !important;
}

.fast-progress-bar {
  .p-progressbar-value-animate {
    transition: width 0.1s ease-in-out !important;
  }
}
