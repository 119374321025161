.btb-card {
  padding: 10px 0px 0px;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  min-height: 0px;
  flex-grow: 1;
  flex-basis: 0;

  .btb-card-header {
    &:not(:first-of-type) {
      margin-top: 12px;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 0;
    margin-right: 12px;
    margin-bottom: 0px;

    h1 {
      padding: 0px;
      margin: 0px;
      &:hover {
        color: var(--link-hover-color);

        span {
          width: 30px;
        }
      }

      span {
        width: 0px;
        overflow-x: hidden;
        transition: width 0.1s;
        color: var(--link-hover-color);
        margin-left: 8px;
      }
    }
  }

  .btb-card-content {
    flex-grow: 1;
    margin: 6px 12px 4px;
    display: flex;
    flex-direction: column;
    min-height: 0px;

    .scroll-box {
      padding: 8px 8px;
    }

    &.scrollable {
      overflow-x: hidden;
      overflow-y: auto;
      margin-right: 0px !important;
      margin-left: 0px !important;
    }

    &.develop {
      align-items: center;
      justify-content: center;

      span {
        font-size: 36px;
      }
    }

    h2 {
      margin-bottom: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 25px;
    }

    h3 {
      margin-bottom: 0px;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
    }
  }

  .btb-card-foot {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 16px 12px;

    button {
      height: 36px;
      padding: 0px 12px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0px;
  }

  &.linked {
    .btb-card-header h1 {
      cursor: pointer;
    }

    .btb-card-link {
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: 16px;

      p {
        margin: 0px;
        cursor: pointer;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.2px;

        &:hover {
          color: var(--link-hover-color);
        }
      }
    }
  }
}
