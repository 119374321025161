.p-button-icon.mat-outlined,
.p-button-icon.mat-rounded,
.p-button-icon.mat-sharp {
  font-size: 1.25rem !important;
}

.mat-done::before {
  content: '\e876';
}

.mat-download::before {
  content: '\f090';
}

.mat-menu-open::before {
  content: '\e9bd';
}

.mat-info::before {
  content: '\e88e';
}

.mat-delete::before {
  content: '\e872';
}

.mat-warning::before {
  content: '\e002';
}

.mat-close::before {
  content: '\e5cd';
}

.mat-edit::before {
  content: '\e3c9';
}

.mat-edit-note::before {
  content: '\e745';
}

.mat-edit-square::before {
  content: '\f88d';
}

.mat-rate-review::before {
  content: '\e560';
}

.mat-add-notes::before {
  content: '\e091';
}

.mat-add::before {
  content: '\e145';
}

.mat-chevrow-right::before {
  content: '\e5cc';
}

.mat-photo-camera::before {
  content: '\e412';
}

.mat-photo-library::before {
  content: '\e413';
}

.mat-arrow-back::before {
  content: '\e5c4';
}

.mat-chevron-left::before {
  content: '\e5cb';
}

.mat-chevron-right::before {
  content: '\e5cc';
}

.mat-check::before {
  content: '\e5ca';
}

.mat-menu::before {
  content: '\e5d2';
}

.mat-cached::before {
  content: '\e86a';
}

.mat-edit-note::before {
  content: '\e745';
}

.mat-lock::before {
  content: '\e897';
}

.mat-lock-open::before {
  content: '\e898';
}

.mat-more-vert::before {
  content: '\e5d4';
}

.mat-file-save::before {
  content: '\f17f';
}

.mat-visibility::before {
  content: '\e8f4';
}

.mat-refresh::before {
  content: '\e5d5';
}

.mat-credit-card::before {
  content: '\e870';
}

.mat-key::before {
  content: '\e73c';
}

.mat-error::before {
  content: '\e000';
}

.mat-bookmark::before {
  content: '\e866';
}

.mat-search::before {
  content: '\e8b6';
}

.mat-filter-alt::before {
  content: '\ef4f';
}

.mat-filter-alt-off::before {
  content: '\eb32';
}

.mat-apartment::before {
  content: '\ea40';
}

.mat-person::before {
  content: '\e7fd';
}
