.btb-tab {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  height: 100%;
  position: relative;
}

.btb-tab-header {
  width: 100%;
  height: 32px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  position: fixed;

  .btb-tab-button {
    height: 100%;
    background-color: #dce7f5;
    border-radius: 4px;
    box-shadow: var(--default-box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;

    &:not(.selected) {
      &:hover {
        background-color: var(--ion-color-secondary-tint);
      }
    }

    &.selected {
      color: white;
      background-color: var(--ion-color-secondary);

      &:hover {
        background-color: var(--ion-color-secondary-shade);
      }
    }

    &:not(:first-child) {
      margin-left: 8px;
    }

    p {
      cursor: pointer;
      margin: 0px 8px;
    }
  }
}

.btb-tab-content {
  width: 100%;
  height: 100%;
  padding: 4px 4px;
  margin-top: 50px;
  flex-grow: 1;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-padding-bottom: 8px;
}

// hide scroll bar when small page
@media (max-width: 1023px) {
  .btb-tab-content {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
