@use '@angular/material' as mat;

$primary: #184a84;
$secondary: #82b2d8;
$accent: #a5cc6b;
$warn: #ef5b5b;
$other: #7a7265;

//FA7268
//8cc2b8
$primary-palette: (
  50: #e3e9f0,
  100: #bac9da,
  200: #8ca5c2,
  300: #5d80a9,
  400: #3b6596,
  500: #184a84,
  600: #15437c,
  700: #113a71,
  800: #0e3267,
  900: #082254,
  A100: #88aaff,
  A200: #5586ff,
  A400: #2262ff,
  A700: #084fff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$secondary-palette: (
  50: #f0f6fa,
  100: #dae8f3,
  200: #c1d9ec,
  300: #a8c9e4,
  400: #95bede,
  500: #82b2d8,
  600: #7aabd4,
  700: #6fa2ce,
  800: #6599c8,
  900: #528abf,
  A100: #ffffff,
  A200: #ecf6ff,
  A400: #b9dcff,
  A700: #a0cfff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$warn-palette: (
  50: #ffe7e7,
  100: #ffc2c2,
  200: #ff999a,
  300: #ff7072,
  400: #ff5253,
  500: #ff3335,
  600: #ff2e30,
  700: #ff2728,
  800: #ff2022,
  900: #ff1416,
  A100: #ffffff,
  A200: #fffbfb,
  A400: #ffc8c8,
  A700: #ffaeaf,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$accent-palette: (
  50: #effaf1,
  100: #d7f2db,
  200: #bde9c3,
  300: #a3e0ab,
  400: #8fda99,
  500: #7bd387,
  600: #73ce7f,
  700: #68c874,
  800: #5ec26a,
  900: #4bb757,
  A100: #ffffff,
  A200: #e0ffe3,
  A400: #adffb5,
  A700: #93ff9e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$accent-50: mat.get-color-from-palette($accent-palette, 50);
$accent-500: mat.get-color-from-palette($accent-palette, 500);
$accent-900: mat.get-color-from-palette($accent-palette, 900);

$warn-50: mat.get-color-from-palette($warn-palette, 50);
$warn-500: mat.get-color-from-palette($warn-palette, 500);
$warn-900: mat.get-color-from-palette($warn-palette, 900);

$primary-50: mat.get-color-from-palette($primary-palette, 50);
$primary-200: mat.get-color-from-palette($primary-palette, 200);
$primary-300: mat.get-color-from-palette($primary-palette, 300);
$primary-500: mat.get-color-from-palette($primary-palette, 500);
$primary-900: mat.get-color-from-palette($primary-palette, 900);

$primary-500-contrast: mat.get-contrast-color-from-palette($primary-palette, 500);

$secondary-50: mat.get-color-from-palette($secondary-palette, 50);
$secondary-500: mat.get-color-from-palette($secondary-palette, 500);
$secondary-900: mat.get-color-from-palette($secondary-palette, 900);

$popover-box-shadow: 0 2px 8px #00000029;
$popover-border-radius: 6px;
