html,
body {
  padding: 0;
  margin: 0;
}

.mat-button-wrapper {
  p {
    margin: 0px;
  }
}

.custom-scrollbar::-webkit-scrollbar-track {
  margin-top: 16px;
  margin-bottom: 16px;
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary);
  border-radius: 10px;
}

.default-page-background {
  height: calc(100vh - 50px) !important;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  background-color: var(--default-background-color);

  .page-content {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 990px;
    position: relative;
    padding: 0px 10px 8px;
    border: none;

    h1 {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    h2 {
      margin-top: 6px;
      margin-bottom: 0px;
      margin-left: 16px;
    }

    h3 {
      margin-bottom: 0px;
      text-align: center;
      width: 100%;
    }

    p {
      margin: 0px;
    }

    .row {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0px;
      position: relative;

      .col {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        margin: 0px;
        position: relative;
      }

      ion-button {
        margin-top: 6px;
      }
    }
  }
}

.page {
  height: 100%;
}

h1,
h2,
h3,
p,
form,
label,
th,
td {
  font-family: var(--font-family) !important;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

html {
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
  html {
    font-size: 13px;
  }
}

hr {
  border-width: thin;
  padding: 0 16px;
  margin: 6px;
}
