@use '@angular/material' as mat;
@import 'libs/shared/src/lib/scss/fonts/nunito/nunito';
@import 'libs/shared/src/lib/scss/fonts/icons/icons';

@include mat.core();

@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';
@import 'prime-theme';

$btb-primary-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($primary-palette, 500),
      accent: mat.define-palette($accent-palette, 500),
      warn: mat.define-palette($warn-palette, 500)
    )
  )
);

$btb-secondary-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($secondary-palette, 500),
      accent: mat.define-palette($accent-palette, 500),
      warn: mat.define-palette($warn-palette, 500)
    )
  )
);

.secondary-theme {
  @include mat.all-component-themes($btb-secondary-theme);
}

@include mat.all-component-themes($btb-primary-theme);

* {
  font-family: 'Nunito', sans-serif;
}

.mat-typography p {
  margin: 0;
}

//.mat-mdc-dialog-container {
//  display: flex !important;
//  padding: 1rem;
//}

.mat-bottom-sheet-container {
  padding: 0 !important;
  overflow: unset !important;
  display: flex !important;
  color: black !important;
}

.mat-mdc-dialog-surface {
  display: flex !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}
