::-webkit-scrollbar-track {
  background: transparent;
  margin: 10px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
  cursor: pointer;
}
