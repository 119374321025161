.btb-page {
  max-width: 1280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.4rem 0 0 1rem;

  &.unlimited-width {
    max-width: unset;
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 0;

  .page-content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    max-width: 1280px;

    &.unlimited-width {
      max-width: unset;
    }
  }

  .page-tab {
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 1rem;

    button {
      &.active-link {
        background-color: $primary-500;
      }
    }
  }
}

.page-content,
.page-tab {
  padding: 0.4rem 2rem !important;
}

@media (max-width: 1023px) {
  .page-content,
  .page-tab {
    padding: 0px 12px;
  }
}

.page-searchbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 1rem;

  mat-form-field {
    flex-grow: 1;

    .mat-form-field-wrapper {
      padding: 0;
      margin: 0;
    }
  }

  button {
    margin-left: 1rem;

    .mat-button-wrapper {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      max-width: 100%;

      p {
        margin: 0rem 1rem;
      }

      i-feather {
        --size: 1.2rem;
        display: flex;
        width: var(--size);
        height: var(--size);

        &:last-child {
          margin-left: auto;
        }
      }
    }

    i-feather {
      height: 20px;
      width: 20px;
      display: flex;
      margin: auto 0.4rem auto auto;
    }
  }
}
